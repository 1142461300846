ul.social{
	display:block;
	list-style: none;
	padding:0;
	margin: 0 auto 1rem;
	li{
		display: inline-block;
		margin: 0 0.25rem;
		a{
			@include ui-hover;
			margin: 0 auto;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width:3rem;
			height: 3rem;
			color:$color;
			padding:0;
			font-size: 1.75rem;
			text-align:center;
			text-decoration: none;
			background:transparent;
			&:before{
				background:rgba(#000,.1);
			}
			&:hover{
				//color:$white;
				background-image: none;
				background-color: transparent;
				&:before{
					opacity: 1;
					transform:none;
				}
			}
			&:before{
				transition:inherit;
				content: '';
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				opacity: 0;
				transform:scale(0.7);
				border-radius:100%;
			}
			.svg-icon{
				position: relative;
			}

			/*
			
			&[href*="facebook.com"]:before{ background: $ext-brand-facebook; }
			&[href*="twitter.com"]:before{ 	background: $ext-brand-twitter; }
			&[href*="linkedin.com"]:before{ background: $ext-brand-linkedin; }
			&[href*="youtube.com"]:before{ 	background: $ext-brand-youtube; }
			&[href*="instagram.com"]:before{background: $ext-brand-instagram; }

			*/
		}
	}
}