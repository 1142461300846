/* Base */
::selection {
	background: $focus;
	color: $white;
}
::-moz-selection {
	background: $focus;
	color: $white;
}

/* Hyperlink */
a{
	position: relative;
	color: inherit;
	text-decoration: none;
//	&:focus{ box-shadow:$focus; }
}
p a,
a.link{
	display: inline;
	position: relative;
	color: inherit;
	line-height: inherit;
	text-decoration: none;
	color:var(--blue);
	font-weight: $font-weight-semibold;
	&:hover{
		color: inherit;
		text-decoration: underline;
	}
//	&:focus{ box-shadow:$focus; }
}

/* Headers */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
	display: block;
	position: relative;
	color:inherit;
	//margin: 0 auto;
	-webkit-text-stroke: 0.001px transparent;
	font-family:	$font-secondary;
	//font-weight: $font-weight-bold;
	font-weight: normal;
	span{
		display: inline;
		//&:nth-of-type(1){ color:var(--blue); }
		//&:nth-of-type(2){ color:var(--orange); }
	}
}
	.h0{
		font-size:6.25rem !important;
		line-height: 1.05 !important;
		/*
		span{
			display: block;
			&:nth-of-type(1){ color:inherit; }
			&:nth-of-type(2){ color:var(--blue); }
			&:nth-of-type(3){ color:var(--orange); }
		}
		&:after{
			content: '';
			display: block;
			margin:2.75rem auto 1.5rem;
			width:4rem;
			height: 2px;
			background:$color;
		}
		*/
	}
	h1, .h1{
		padding: 		0 0 1.5rem;
		font-size:		3.0rem;
		line-height:	1.15;
		letter-spacing: -0.02em;
		&.display{
			font-size: 4.5rem;
			font-weight: $font-weight-black;
			line-height: 1.15;
			padding:0 0 1rem;
		}
	}
	h2, .h2{
		padding: 		0 0 1.5rem;
		font-size:		3rem;
		line-height:	1.15;
	}
	h3, .h3{
		padding: 		0 0 1.5rem;
		font-size:		1.75rem;
		line-height:	1.25;
	}
	h4, .h4{
		padding: 		0 0 1rem;
		font-size:		1.5rem;
		line-height:	1.15;
	}
	h5, .h5{
		padding: 		0 0 1rem;
		font-size:		1.25rem;
		line-height:	1;
	}
	h6, .h6{
		padding: 		0 0 1rem;
		font-size:		1.1rem;
		line-height:	1;
	}

ul{
	//list-style: disc inside;
}
ol{
	//list-style: decimal inside;
}
ul,ol{
	list-style-position:outside;
	margin:0 auto 1.5em 1rem;
	ul,ol{
		margin:0 auto 0 1rem;
	}
}

/* Block quote */
blockquote{
	display: block;
	margin:0 auto;
	padding: 2rem 1rem;
	font-size:1.5rem;
	line-height: 1.4;
	cite{
		display: block;
		margin:0 auto;
		padding:0 0 1rem;
		font-size:1.4rem;
		font-family: $font-secondary;
		font-weight: $font-weight-semibold;
		font-style: normal;
	}
	p strong{
		color:var(--blue);
	}
}


/* Horizontal Rule */
hr{
	display: block;
	height: 1px;
	margin:2rem auto 3rem;
	background:$grey;
	opacity: 0.25;
}
.text-center hr{ 	margin-left: auto; margin-right: auto; }
.text-right hr{ 	margin-left: auto; }
.text-left hr{		margin-right: auto; }

/* Pagination */
.pagination{
	text-align: center;
	ol,ul{
		list-style: none;
		margin: 2rem auto 0;
		font-size: 0.9rem;
	}
	li{
		display: inline-block;
		margin:0.5rem auto;
		a{
			display: block;
			padding: 0.5em 0.9em;
			color:$black;
			border-radius:5rem;
			text-decoration: none;
			font-weight: $font-weight-bold;
			&:hover{
				color: $black;
				background-color: $grey-light;
			}
			& .material-icons{
				display: inline-block;
				vertical-align: middle;
				font-size:inherit;
			}
		}
		&.prev a,
		&.next a{
		}
		&.active a{
			cursor: default;
			color: $white;
			background-color: $color-base;
		}
		&.disabled a{
			cursor: default;
			color:$grey;
			background-color: transparent;
		}
		&:last-child{
			margin-right: 0;
		}
	}
}


/* Table */
table{
	overflow: hidden;
	display: table;
	margin: 1rem auto 2rem;
	border-spacing: 0;
	border-collapse: collapse;
	width: auto;
	&[style]{ width: 100% !important; }
	tr th, tr td{
		padding: 1.25rem;
		width: auto;
		&[style]{ width: auto !important; }
		border-right:1px solid rgba(white,0.1);
		border-bottom:1px solid rgba(white,0.1);
	}
	thead{
		line-height: 1.3;
		font-family: $font-secondary;
		tr{
			th{
				font-size:1.2rem;
				max-width:12rem;
				@media screen and (max-width: $bp-one) {
					max-width:7rem;
				}
				@media screen and (max-width: $bp-two) {
					max-width:10rem;
				}
				@media screen and (max-width: $bp-three) {
					max-width:none;
				}
			}
		}
	}
	tfoot{
		text-align: left;
		line-height: 1.3;
		font-size: 0.8rem;
		tr{
			th{
			}
		}
	}
	tbody{
		tr {
			&:nth-child(even){
				//background: rgba(0,0,0,0.05);
			}
			td{
			}
		}
	}
	.logo{
		width:100%;
		margin:0 auto;
		svg{
			width:100%;
		}
	}
}

.breadcrumbs{
	ul{
		list-style: none;
		padding:0;
		margin:0;
		li{
			display: inline-block;
			font-size:0.85rem;
			a{
				display: inline-block;
				padding:0 0.5rem;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
			&:after{
				content: '/';
				display: inline-block;
				font-size:1rem;
				color:$color-base;
				font-weight: $font-weight-bold;
			}
			&:last-child{
				padding:0 0.5rem;
				font-weight: $font-weight-bold;
				&:after{ display: none; }
			}
		}
	}
}