/* Common */

/* Layout */
html{
	min-height: 100%;
	body{
		min-height: 100%;
		overflow-y: scroll;
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: 100%;
	}
}

/* Containers */
#everything{
	height: 100%;
	position: relative;
    #body{
		height: 100%;
    }
}

/* Video Embeds */
.ratio-container{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0px;
	height: 0;
	background: black;
	iframe,video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
	}
}
.iframe-container{
	display: block;
	position: relative;
	background: white;
	width:100%;
	iframe{
		display: block;
		width:100%;
		background: white url($img_path + 'ajax-loader.gif') no-repeat center;
	}
}
.iframe-container.youtube{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0px;
	height: 0;
	background: black;
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
	}
}
.modal .iframe-container{
	margin:-2rem;
	width:auto;
}

/* Vertical Alignment Containers */
.table{
	display: table;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	.cell{
		display: table-cell;
		vertical-align: middle;
		width: 100%;
	}
}

/* Tables */
.table-container{
	display: block;
	position: relative;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
}

/* Typography */
strong, .strong{	font-weight: $font-weight-bold;	}
b{					font-weight: $font-weight-bold;	}
s, strike, del{		text-decoration: line-through;	}
em, .em, i {		font-style: italic;				}
small, .small{		font-size: 0.8rem;				}
big, .large{		font-size: 1.5rem;				}
sup{ 				font-size: 0.4em; 				}

/* Alignment */
.float-left{ 	float: left;	}
.float-right{	float: right;	}
.float-none{	float: none;	}

.text-left{		text-align: left 	!important;	}
.text-right{	text-align: right 	!important;	}
.text-center{	text-align: center 	!important;	}

/* Elements */
img{
	display: inline-block;
	max-width: 100%;
	height: auto;
	&.block{
		display: block;
	}
}

/* Lists */
ul.reset, ol.reset{
	font-size: 0;
	list-style: none;
	li{
		font-size: $font-size;
		display: inline-block;
        vertical-align: top;
	}
}
ul.unstyled, ol.unstyled{
	list-style: none;
	margin-left:0;
	padding-left:0;
	li{
		display: block;
	}
}

/* Misc */
.hidden{ display: none !important; }
.show{ display: block !important; }