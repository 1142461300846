:root{
	--ext-brand-mellow	: #00a29c;
	--mellow-01			: #f3426c;
	--mellow-02			: #f2e400;
	--mellow-03			: #652f6c;
	--mellow-04			: #00a6ce;
	--mellow-05			: #00a29c;
}
@keyframes mellow-rainbow {
  0%, 100% { --color:var(--mellow-01); }
  20% { --color:var(--mellow-02); }
  40% { --color:var(--mellow-03); }
  60% { --color:var(--mellow-04); }
  80% { --color:var(--mellow-05); }
}
a.mellow-link{
	transition:all 150ms ease-out;
	color:var(--ext-brand-mellow) !important;
	display: inline-block;
	position:relative;
	text-decoration: none;
	&:after{
		transition:inherit;
		animation: mellow-rainbow 1s linear both infinite;
		z-index: 1;
		content: ' ';
		position: absolute;
		left: 0;
		bottom: 0%;
		width:0;
		height:1px;
		background-color:var(--color);
	}
	&:hover{
		color:$color !important;
		color:#fff!important;
		transform:translate(0,-0.1rem);
		text-decoration:none;
	}
	&:hover:after{
		width:100%;
	}
}
