.buttons{
	margin:0.5rem auto 1rem;
}
.button{
	@include ui-hover();
	cursor:pointer;
	position: relative;
	display:inline-block;
	z-index: 2;
	line-height: 2;
	font-size: 0.9rem;
	font-family: $font-primary;
	font-weight: $font-weight-semibold;
	text-align: center;
	text-decoration: none;
	line-height: 1.2;
	color:$primary-button-text;
	background: $primary-button-bg;
	background-size:100% 100%;
	border:none;
	border-radius:0.25rem;
	overflow: hidden;

	margin: 1rem 0 0;
	padding: 1rem 2rem;
	transition:background $transition-duration $transition-ease, color $transition-duration $transition-ease;

	/*

	&:before{
		transition:opacity $transition-duration $transition-ease;
		content: '';
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		background:rgba(black,0.1);
		opacity: 0;
	}

	*/
	
	&:hover{
		text-decoration: none;
		//&:before{ opacity: 1; }
		background:darken($primary, 10%);
	}
	
	&:active{
	}

	/*

	&.white{
		background:#fff;
		color:$primary;
		&:hover{
			color:$primary;
		}
	}

	*/

	.svg-icon{
		margin-left: 0.25rem;
	}

	&.sml{
		font-size:0.7rem;
		padding:0.25rem 0.25rem;
	}

	&.ghost{
		color:currentColor;
		background:transparent;
		box-shadow:none;
	}

	/*

	&.cta{
		color:$white;
		background:$black;
		&:hover{
			background:$color-base;
		}
	}

	&.secondary{
		color:$black;
		background:transparent;
		box-shadow:inset 0 0 0 1px $black;
	}

	*/

	&.wide{
		display: block;
		width:100%;
	}


    &.secondary{
        background:$secondary;
        &:hover{
			background:darken($secondary, 10%);
        }
    }
    &.white{
        color:$primary;
        background:$white;
        &:hover{
			background:darken($white, 10%);
        }
    }
    &.light{
    	background:$light;
        &:hover{
			background:darken($light, 10%);
        }
    }
    &.outline{
        background:none;
        border:1px solid $primary;
        color:$primary;
        &:hover{
        	color:$white;
        	background:$primary;
        }
        &.secondary{
            border-color: $secondary;
            color:$secondary;
            &:hover{
            	background:$secondary;
            	color:$white;
            }
        }
        &.white{
            color:$white;
            border-color:$white;
            &:hover{
            	background:$white;
            	color:$primary;
            }
        }
        &.light{
        	border-color:$light;
            &:hover{
            	background:$light;
            }
        }
    }

	+.button{ margin-left: 1rem; }
	
	&:focus{ box-shadow:0 0 0 2px $focus; }
}
a:hover .button,
a:focus .button,
.button:hover,
.button:focus{
	cursor: pointer;
}
a:active .button,
.button:active{
}



.bg-primary .button.outline.white:hover{
	color:$primary;
}
.bg-secondary .button.outline.white:hover{
	color:$secondary;
}
.bg-dark .button.outline.white:hover{
	color:$dark;
}