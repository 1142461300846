/* Body */
html{
	scroll-behavior:smooth;
}
body{
	color:$color;
	color:$dark;
	font: $font-size $font-primary;
	font-weight: $font-weight-regular;
	line-height: 2;
	background:$background;
	font-size: 1.125rem;
	line-height: 1.9;
}

main#main{
	overflow: hidden;
}

a.anchor{
	@include ui-hover;
	display: inline-block;
	text-decoration:none;
	padding:2px;
	&.block{ display: block; }
	&:focus{
		box-shadow:inset 0 0 0 2px $focus;
	}
	&:hover{
		transform:scale(1.01);
		box-shadow:none;
	}
	&:active{
		transform:scale(1);
	}
}

p{
	padding: 0 0 1.5rem;
	//font-size: 1.125rem;
	//line-height: 1.9;
	//color:$grey-dark;
	&.small{ font-size: 1rem; line-height: 1.6; }
	&.large{ font-size: 1.4rem; }
//	strong{ color:$color; }
}

.text-muted{ opacity: 0.5; }
.columns-two{
	columns:2;
	@media screen and (max-width: $bp-three) {
		columns:1;
	}
}


.flex{
	margin:0 auto 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	input,button{
		display: inline-block;
		width:auto;
		margin:0 0.5rem 0 0;
	}
}

.ratio-container,
.iframe-container{
	overflow: hidden;
	display: block;
	margin:0;
	border-radius:$border-radius;
	box-shadow:$box-shadow-lrg;
}

/*

$padding:2rem;
$padding-lrg:7.75rem;
section{
	position:relative;
	padding:$padding 0;

	&.section-header{
		padding: $padding 0 0;
	}

	&.section-large{
		padding: $padding-lrg 0 ;
	}
	&.section-small{
		padding: 1rem 0;
	}
/*
	@media screen and (max-width: $bp-three) {
		$padding:4.5rem;
		$padding-lrg:5rem;
		padding:$padding 0 !important;
		margin:0 !important;
		&:first-of-type{
			padding-top:10rem !important;
		}
	}

}

*/

.content{
	position:relative;
	padding:1rem 1.5rem;
	ul,ol{
		//color:rgba($color,0.6);
		list-style-position: outside;
		.svg-icon{ margin:0 0.25rem; }
	}
	h1,h2,h3,h4,h5,h6{  }
}

article{
//	$padding:10rem;
//	position:relative;
//	padding:$padding 0;
	img{
		display: block;
		margin:2rem auto 4rem;
	}
}




//.panel-offset{ margin:-14rem 0 -10rem; }
.panel{
	z-index: 1;
	position:relative;
	border-radius:$border-radius;
	margin:2rem 1rem 3rem;
	padding:2rem;
	//background-color:$white;
	box-shadow:0 0 0 1px rgba(0, 0, 0, 0.02), $box-shadow-lrg;
	&.blog-panel{ overflow:hidden; }

	.cover-image{
		color:white;
		margin:-2rem -2rem 2rem;
		padding:4rem 2rem 0;
	 	border-radius:$border-radius $border-radius 0 0;
		img{
			display: block;
			width:90%;
			margin:-6rem auto 1rem;
		}
		h4{
			padding-bottom:4rem;
		}
		.cover-image{
			height:50rem;
		}
	}
	img{
		margin:0 auto;
		width:80%;
	}
	.blog-image,
	.panel-img{
		border-top-left-radius:$border-radius;
		border-top-right-radius:$border-radius;
		margin:-2rem -2rem 2rem;
		height: 16rem;
		overflow:hidden;
		&.temp{
			background:whiteSmoke;
			padding-bottom:100%;
		}
		img{
			max-width:none;
			width:100%;
			height: 100%;
			object-fit: cover;
		}
		@media screen and (max-width: $bp-three) {
			//margin:-2rem -2rem 2rem;
		}
	}
}

.content,
.panel{
	.icon-img{
		margin-bottom: 2rem;
		img{
			display: block;
			margin:0 auto;
			width:auto;
			height:4rem;
		}
	}
}


.unstyled{
	font-size:1.0rem;
	text-align: left;
	li{
		position: relative;
		padding:0.5rem 0;
		padding-left:3rem;
		.svg-icon{
			position:absolute;
			top:1rem;
			left:0;
			font-size:1.5em;
			margin-right:0.5rem;
		}
	}
}

.bg-rounded-top{
	$size:20rem;
	margin-top:$size*0.5;
	position:relative;
	//overflow: hidden;
	&:before{
		z-index:-1;
		content: '';
		display: block;
		position:absolute;
		top:-$size*0.25;
		left:-10%;
		right:-10%;
		height:$size;
		background:inherit;
		border-radius: 100% 100% 0 0;
	}
}
.section-arch{
	$size:30rem;
	padding:$size*0.375 0;
	position:relative;
	overflow: hidden;
	.cta:hover{ background:black; }
	&:before,&:after{
		content: '';
		display: block;
		position:absolute;
		left:-10%;
		right:-10%;
		height:$size;
		background:white;
	}
	&:before{
		top:-($size - $size*0.25);
		border-radius: 0 0 100% 100%;
	}
	&:after{
		bottom:-($size - $size*0.25);
		border-radius: 100% 100% 0 0;
	}
	@media screen and (max-width: $bp-two) {
		$size:10rem;
		padding:$size*0.375 0;
		&:before,&:after{
			height:$size;
		}
		&:before{
			top:-($size - $size*0.25);
		}
		&:after{
			bottom:-($size - $size*0.25);
		}
	}
}

.margin-offset-top{
	margin-top:-100%;
}
.margin-offset-bottom{
	margin-bottom:-100%;
}

.panel.button-bottom{
	padding-bottom:4rem;
	.button{
		position: absolute;
		bottom:1rem;
		left:1rem;
		right:1rem;
		width:auto;
	}
}
.use-cases{
	display: flex;
	flex-grow: 1;
	flex-basis: 100%;
	justify-content: center;
	align-items:center;
	text-align: center;
	margin:2rem auto 0;
	.anchor{
		width:100%;
		margin:0 1%;
	}
	.panel{
		margin:2rem 0;
		width:100%;
	}
	@media screen and (max-width: $max-width) {
		display: block;
		.anchor{
			display: inline-block;
			vertical-align: top;
			width:31%;
		}
	}
	@media screen and (max-width: $bp-three) {
		.anchor{
			display: block;
			margin:3rem 1rem 2rem;
			width:auto;
		}
	}
}


.testimonial{
	margin:6rem 1rem 2rem;
	width:100%;
	img{
		display: block;
		width:90%;
		max-width:16rem;
		margin:-8rem auto 0;
	}
}


.image-offset{
	margin:6rem 0 2rem;
	width:100%;
	img{
		display: block;
		width:90%;
		max-width:16rem;
		margin:-4rem auto 2rem;
	}
}