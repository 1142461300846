.alignleft,
.aligncenter,
.alignright,
.alignnone,
.size-full{
    border-radius:2px;
    //box-shadow:$box-shadow;
}

.alignleft {
    float: left;
    margin:0 1rem 2rem 0;
}
.aligncenter {
    display: block;
    margin: 0 auto 2rem auto;
}
.alignright {
    float: right;
    margin:0 0 2rem 1rem;
}
.alignnone {
    float: none;
    display:block;
    margin:0 0 2rem 0;
}
.size-full {
    width:100%;
    height:auto;
   /* margin:0 0 2rem 0; */
   // box-shadow:$box-shadow-lrg;
}
p .alignleft {
    float: left;
    margin:2rem 3rem 2rem 0;
}
p .aligncenter {
    display: block;
    margin: 2rem auto 2rem auto;
}
p .alignright {
    float: right;
    margin:2rem 0 2rem 3rem;
}
p .alignnone {
    float: none;
    display:block;
    margin:2rem 0 2rem 0;
}
p .size-full {
    width:100%;
    height:auto;
    margin:2rem 0 2rem 0;
}

.gallery img{
    border:transparent !important;
}


// CUSTOM
code{
    @include BorderRadius;
    padding:0.2rem 0.5rem;
    margin: 0 0.1rem;
    color:$white;
    border:1px solid $grey-light;
    background: $grey;
}

.wp-caption{
    max-width: 100%;
    display: block;
    position:relative;
    padding: 1rem;
    margin: 1rem auto 2rem;
    text-align: center;

    .wp-caption-text{
        padding:0;
        font-size: 0.9rem;
        color:$grey-dark;
    }
}

abbr{
    @include Transitions;
    position:relative;
    text-decoration: none;
    border-bottom:1px dotted $color;
    &:before{
        content: attr(title);
        @include BoxShadow;
        @include BorderRadius;
        @include Animated(fadeInUp);
        display: none;
        position:absolute;
        bottom: 100%;
        left: -50%;
        padding:0.2rem 0.5rem;
        color:$color;
        font-size: 0.8rem;
        border:1px solid $color-base;
        background: $color-base-light;

    }
    &:hover{
        color:$white;
        background:$color-base;
        &:before{ display: block; }
    }
}

ins{
    @include Transitions;
    position:relative;
    text-decoration: none;
    border-bottom:1px dashed $grey-light;
    &:before{
        content: 'Date inserted: '  attr(datetime);
        @include BoxShadow;
        @include BorderRadius;
        @include Animated(fadeInUp);
        display: none;
        position:absolute;
        top: 100%;
        left: 0%;
        padding:0.2rem 0.5rem;
        color:$color;
        font-size: 0.8rem;
        border:1px solid $color-base;
        background: $color-base-light;

    }
    &:hover{
        color:$white;
        background:$color-base;
        &:before{ display: block; }
    }
}

mark{
    $highlight: transparentize($color-base,0.15);
    display: inline-block;
    position:relative;
    background: none;
    padding:0 0.4rem;
    color:inherit;
    z-index: 2;
    font-weight: $font-weight-regular;
    color:$white;

    &:before, &:after {
      z-index: -1;
      content: " ";
      display: block;
      height: 90%;
      width: 100%;
      position: absolute;
    }
    &:before {
      top: -1px;
      left: -1px;
      transform: rotate(2deg);
      background: $highlight;
    }
    &:after {
      top: 3px;
      right: -2px;
      background: $highlight;
    }


}


address{
    @include BoxShadow;
    @include BorderRadius;
    display: inline-block;
    margin:0 auto 2rem;
    padding:1rem 1rem;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    white-space: pre;
    background:$grey;
}