// section mods

.block-section{
    &.full-width{
        padding-top:0;
        padding-bottom:0;
        .container_12{
            width:100%;
            max-width: 100%;
            .entry-media{
                padding:0;
                min-height:200px;
                .slick-list{
                    margin:0;
                }
                .google-map{
                    position: absolute;
                    top:0;
                    left:0;
                    height:100%;
                    width:100%;
                }
            }
            @media (min-width:$bp-three+1){
                display: flex;
                > .grid_6{
                    margin-left:0;
                    margin-right: 0;
                    width:50%;
                    max-width: 50%;
                }
                .entry-content{
                    padding-top:$section-spacer;
                    padding-bottom:$section-spacer;
                    max-width: 540px;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    align-items:center;
                    height:100%;
                    min-height:600px;
                    .inner{
                        width:100%;
                    }
                }
                .entry-media{
                    padding:0;
                    height:100%;
                    position: relative;
                    padding:0;
                    img{
                        position: absolute;
                        top:0;
                        left:0;
                        object-fit:cover;
                        object-position: center center;
                        width:100%;
                        height:100%;
                        max-width: none;
                    }
                    .slick-slider,
                    .slick-list,
                    .slick-track,
                    .slick-slide{
                        height:100%;
                        margin:0;
                    }
                    .slick-slide{
                        position: relative;
                    }
                }
            }
        }
    }
}

// side by side content

.section__side-by-side-content{
    .slick-list{
    margin:0;
    }
    .hm-img{
        height:100%;
        width:100%;
    }
}

// cards

/*

.section__cards{
    .panel{
        img{
            height:4rem;
            width:auto;
            display: block;
            margin:0 auto;
            margin-bottom: 2rem;
        }
    }
}

*/

// call to action

.section__call-to-action{
    margin:0;
}

.section__membership-comparison{
    margin-bottom:0;
    .membership_features{
        li:before{
            background-image:url('../images/check.svg')!important;
        }
    }
    .membership-cards{
        padding-top:4rem;
        padding-bottom:2rem;
        .membership-item{
            display: flex;
            flex-direction:column;
            .membership_cost{
                padding-top: 2rem;
                border-top:1px solid $dark;
                margin-top: auto;
                strong{
                    display: block;
                    font-size: 1.55rem;
                    line-height: 1;
                }
            }
            h3{
                font-size:2rem;
                line-height: 1;
            }
            .button{
                width:100%;
                display: block;
            }
        }
    }
    .membership-gallery{
        position: relative;
        display: flex;

        /*

        &:before{
            content:'';
            display: block;
            position: absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
            width: 0; 
            height: 0; 
            border-left:2.75rem solid transparent;
            border-right:2.75rem solid transparent;
            border-top:2.75rem solid #fff;
        }

        */

        div{
            width:100%;
            height:300px;

            @media screen and (max-width: $bp-three) {
                &:nth-child(1n+5){
                    display: none;
                }
            }
            img{
                height:300px;
                width:100%;
                object-fit:cover;
                object-position:center center;
                border-radius: 0;
            }
        }
    }
}


// featured link

.section__featured-link{
    margin:3rem!important;
    border-radius: $border-radius;
    overflow: hidden;
    .button{
        margin-top: 0;
    }
    .featured-link__text{
        p{
            font-size: 1.5rem;
            &:only-child,
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
    @media screen and (max-width: $bp-three) {
        margin:3rem 2rem!important;
        text-align: center;
    }
}

// map

.section__map{
    padding-top:$section-spacer;
    padding-bottom:$section-spacer;
    overflow: hidden;
    .google-map-container{
        position:absolute;
        z-index: 1;
        top:0;
        left:0;
        width:100%;
        height:100%;
        .google-map{
            position: absolute!important;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
    .container_12{
        position: relative;
        z-index: 2;
    }
    .panel{
        box-shadow: 0px 0px 20px 0px rgba(#000, .1);
        img{
            object-fit:cover;
            object-position: center center;
            width:100%;
            max-width: 100%;
            height:10rem;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-width: $bp-three) {
        padding-top:0;
        padding-bottom:2rem;
        background:#eee;
        .google-map-container{
            height:35rem;
            //position: static;
            position: relative;
            top:auto;
            left:auto;
            &:after{
                left:0;
                bottom:0;
                position: absolute;
                height:10rem;
                z-index: 1;
                width:100%;
                content:'';
                display: block;
                background: linear-gradient(180deg, rgba(238,238,238,0) 0%, rgba(238,238,238,1) 100%);
            }
            > div{
                height:40rem;
                margin-top: -5rem;
            }
        }
        .container_12{
            margin-top: -10rem;
        }
    }
}

// cards

.section__cards{
    .slick-gallery.container_12,
    .card-row{
        margin-top: -2rem;
        margin-bottom: -2rem;
        .panel{

        }
    }
}

.section__gallery{
    .gallery-grid{
        display: flex;
        justify-content:space-between;
        flex-wrap:wrap;
        flex-direction:row;
        .gallery-item{
            width:32%;
            margin-bottom: 2%;
        }
    }
    .gallery-container{
        &.full-width{
            padding-left:3rem;
            padding-right:3rem;
            @media screen and (max-width: $bp-three) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        a{
        //    display: block;
        //    padding:5px;
        }
        img{
            margin:0;
            display: block;
        }
    }
    .slick-image-gallery{
        .gallery-item{
            margin:0 5px;
        }
    }
}

/* under development */


.section__tabbed-content{
    .tab-nav.panel{
        margin:0;
        list-style-type:none;
        display: flex;
        flex-direction:row;
        padding:.25rem .5rem;
        li,
        li a{
            display: block;
            width:100%;
        }
        li{
            margin:.25rem;
            a{
                transition:background $transition-duration $transition-ease;
                padding:1.25rem .5rem;
                border-radius:$border-radius;
                font-weight: bold;
                &:hover{
                    background:$light;
                }
            }
            &.active{
                a{
                    background:$light;
                }
            }
        }

    }
}