$section-spacer : 6rem;
$section-spacer-lg : 6rem;

section,
.section{
    position:relative;
    margin-top: $section-spacer;
    margin-bottom: $section-spacer;
    /*
    &.section-large{
        margin-top: $section-spacer-lg;
        margin-bottom: $section-spacer-lg;
    }
    */
    &.section-padded{
        margin-top: 0;
        margin-bottom:0;
        padding-top: $section-spacer;
        padding-bottom: $section-spacer;
        /*
        &.section-large{
            margin-top: 0;
            margin-bottom:0;
            padding-top: $section-spacer-lg;
            padding-bottom: $section-spacer-lg;
        }
        */
    }
    &.no-margin{
        margin-top: 0;
        margin-bottom: 0;
    }
    .section-area:not(:last-child){
        margin-bottom: $section-spacer/2;
    }
}

.small-heading{
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .15rem;
    color:$primary;
}

$colors: (
    primary: $primary,
    secondary: $secondary,
    light: $light,
    light-alt: $light-alt,
    dark : $dark,
    white : $white
);
@mixin color-classes($attr : 'background-color') {
    @each $name, $hex in $colors {
        &-#{$name} {
            #{$attr}: $hex;
        }
    }
}
.text {
  @include color-classes('color');
}
.bg {
  @include color-classes();
}
.text-reset,
.text-reset *{
    color:inherit;
}
.mobile-height-image{
    @media (max-width:$bp-three){
        img{
            object-fit:cover;
            object-position:center center;
            height:16rem;
        }
    }
}
.rounded{
    border-radius: $border-radius;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}


.bg-light-gradient{
    background: linear-gradient(to bottom, transparent, $light) no-repeat bottom;
    background-size: 100% 50%;
    border-bottom:darken($light, 5%);
}