body.open-modal{
	overflow: hidden;
}

.modal{
	display: none;
	z-index: 999;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height: 100%;

	&.show{
		display: block;
		opacity: 1;
	}

	.modal-close{
		z-index: 3;
		cursor: pointer;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		right: 0;
		transform:translate(50%, -50%);
		font-size: 1.5rem;
		line-height:0;
		width:2em;
		height:2em;
		color:$white;
		background:$color-base;
		border-radius:10rem;
		padding:1rem;
		&:hover{
			background:darken($color-base,5);
		}
	}

	.container{
		@include Animated(fadeIn, $transition-duration);
		z-index: 2;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items:center;
		background:rgba(black,0.8);
		background-size:cover;
		overflow-y:scroll;
		padding:1rem 0.25rem;
		.panel{
			@include Animated(fadeInUp);
			position: relative;
			padding:2rem;
			margin:2rem 2rem;
			text-align: center;
			max-width:$bp-one;
		}
	}
}