.topbar{
	z-index: 999;
	position: relative;
	//padding:1rem 0;
	background:rgba(white,0.8);
	backdrop-filter:blur(2rem);
	transition: all .25s ease!important;
	//background:#fff;
	border-bottom:1px solid #eee;
	#header{
		display: inline-block;
		vertical-align: middle;
	}
	.nav-bar{
		display: inline-block;
		vertical-align: middle;
	}
	.button{
		display: inline-block;
		width:auto;
		margin:0;
		&+.button{ margin-left: 1rem; }
	}
	@media screen and (max-width: $bp-one) {
		padding:1rem 0;
		.button{
			padding-right: 1rem;
			padding-left:1rem;
		}
		#header{
			padding:0.5rem 0;
		}
	}
}

main#main{
//	padding-top:8rem;
}

#header{
	.logo{
		@include ui-hover;
		z-index: 2;
		position: relative;
		display: block;
		padding:0 1rem 0;
		max-width:18rem;
		width: 100%;
		width:100%;
		svg{
			transition:inherit;
			display: block;
			margin:0;
			width:100%;
			height: auto;
		}
	}
}

footer.footer{
	//border-top:1px solid #eee;
	//padding-top:$section-spacer;
	.callout{
		margin:-15rem auto 0;
		p{ padding:0; }
	}
	.footer-title{
		@extend .h5;
		padding:0 0 1rem;
	}

	.footer-text{
		font-size:0.8rem;
		padding:0;
		a{ color:var(--blue); }
	}

	.logo{
		@include ui-hover;
		display: block;
		margin:0 auto;
		padding:1rem;
		border-radius:$border-radius;
		img, svg{
			transition:inherit;
			display: block;
			width:100%;
			height: auto;
		}
//		&:focus{ box-shadow:$focus; }
	}

	nav{
		ul{
			display: block;
			list-style: none;
			padding:0;
			margin:0;
			li{
				display: block;
				a{
					display: block;
				}
			}
		}
	}
	.social{
		//text-align: right;
		@media screen and (max-width: $bp-three) {
		//	text-align: center;
		}
	}
}

main#main{
}


// scrolly stuff

@media (min-width:1024px){

	#main{
		padding-top:8rem;
	}

	.topbar{
		position: fixed;
		top:0;
		left:0;
		width:100%;
		z-index:9999;
		.container_12{
			height:8rem;
			transition:all .25s ease;	
		}
	}
	.main-nav{
		transition:all .25s ease;
	}
	#header .logo,
	#header .logo img{
		transition:all .25s ease;
	}
	body.scrolled .topbar{
		.container_12{
			height:6rem;
		}
		.main-nav{
			margin:0;
		}
		#header .logo{
			max-width:11rem;
		}
	}
}