/* BRAND */
//$primary : #DD3E5D;
$primary : #E27462;
$secondary : #286564;
$light : #EFE7E4;
$light-alt : #E1EEEE;
$dark : #002638;

/* GENERIC */
$green : green;
$red : red;
$black : black;
$white : white;
$grey : grey;
$grey-light : $light;
$grey-dark : #777;

$shadow:0px 0.25rem 2rem -1rem rgba($black, 0.25);
$shadow-sml:0px 0.25rem 2rem -1rem rgba($black, 0.25);

/* PATHS */
$img_path: "../images/";

/* Colours */
//$color-base				: $orange;
$color-base				: $primary;
$color-base-dark		: darken($color-base, 20%);
$color-base-light		: lighten($color-base, 10%);

$valid					: $green;
$error					: $red;

$dim					: rgba($black, 0.1);
$dimmer					: rgba($black, 0.2);

/* External Brand Colours */
$ext-brand-patreon	: #eb5254;
	$ext-brand-patreon-gradient	: radial($ext-brand-patreon);
$ext-brand-discord	: #7289DA;
	$ext-brand-discord-gradient	: radial($ext-brand-discord);
$ext-brand-twitter 	: #1DA1F2;
	$ext-brand-twitter-gradient	: radial($ext-brand-twitter);
$ext-brand-twitch 	: #9146ff;
	$ext-brand-twitch-gradient	: radial($ext-brand-twitch);
$ext-brand-facebook	: #3b5998;
	$ext-brand-facebook-gradient: radial($ext-brand-facebook);
$ext-brand-linkedin	: #0077b5;
	$ext-brand-linkedin-gradient: radial($ext-brand-linkedin);
$ext-brand-youtube	: #ff0000;
	$ext-brand-youtube-gradient	: radial($ext-brand-youtube);
$ext-brand-instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

@font-face {
    font-family: 'Rozha One';
    src: url('../fonts/rozhaone-regular-webfont.woff2') format('woff2'),
         url('../fonts/rozhaone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$font-size 				: 16px;
$font-line-height		: 1;

$font-primary			: "futura-pt", "Helvetica Neue", "Helvetica", Arial, sans-serif;
$font-secondary			: "Rozha One", "Helvetica Neue", "Helvetica", Arial, sans-serif;

$font-weight-regular	: 400;
$font-weight-semibold	: 600;
$font-weight-bold		: 700;
$font-weight-black		: 900;


/* Layout */
$max-width				: 1240px;
$bp-one					: 1080px;
$bp-two					: 920px;
$bp-three				: 640px;
$bp-four				: 420px;


/* Animation */
$transition-duration	: 220ms;
$transition-ease		: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$animation-duration		: 700ms;
$animation-ease			: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$border-radius			: 0.25rem;
$border-radius 			: 1rem;

/* Specific Variables */
	
// Base
	$color				: $black;
	$background			: #ffffff;
	//$hr					: $grey-light;
	$hr					: $light;


	$text-shadow		: 0 1px 2px rgba($black, 0.15);
	$box-shadow			: $shadow-sml;
	$box-shadow-lrg		: $shadow;


	//$focus:#{rgba(#00AEEF,0.5)};
	$focus:#{rgba($color-base,0.5)};


// Hyperlinks
	$link-color			: $color-base;
	$link-hover-color	: $color-base-dark;

// Button
	$button-color		: $white;
	$button-bg			: $color-base;
	

	$primary-bg:$color-base;
	$primary-text:$white;
	$primary-button-bg:$color-base;
	$primary-button-text:$white;


// Easing function variables - http://easings.net/#easeOutQuint

// SINE
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// QUAD
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// CUBIC
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// QUART
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

// QUINT
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// EXPO
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

// CIRC
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// BACK
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

