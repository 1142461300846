body{
    color:$dark;
}

// row flipper

.container_12.row-reverse{
    @media (min-width:641px){
        display: flex;
        flex-direction:row-reverse;
    }
}

// misc

img{
    display: block;
    width:100%;
    height:auto;
    max-width: 100%;
    margin:0;
    //border-radius: $border-radius;
    border-radius: 0;
}

.rounded{
    border-radius: $border-radius;
}

.bg-cover img,
.section__side-by-side-content.full-width img,
.logo img{
    border-radius: 0;
}

// instagram plugin

#sb_instagram #sbi_load span.sbi_follow_btn a,
#sb_instagram #sbi_load a.sbi_load_btn{
    @extend .button;
}

// direct contact

.direct-contact{
    .contact-detail{
        margin-bottom: 1rem;
    }
    .small-heading{
        padding-bottom: .5rem;
    }
    .contact-data{
        margin:0;
        padding:0;
        font-weight: normal;
    }
    &.col-layout{
        padding: $section-spacer/2 0;
        .contact-details{
            display: flex;
            .contact-detail{
                margin-bottom: 0;
                margin-right:4rem;
            }
        }
        @media screen and (max-width: $bp-three) {
            .contact-details{
                display: block;
                .contact-detail{
                    margin-bottom: 2rem;
                    margin-right: 0;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

// footer socials

footer{
    .social{
        margin:0;
        margin-left: -1.15rem!important;
        svg{
            fill:#fff;
        }
    }
    // footer nav
    .credit-bar{
        ul{
            text-align: right;
            font-size: .8rem;
            list-style: none;
            margin:0;
            padding:0;
            li{
                display: inline-block;
                margin-left: 1rem;
            }
            a{
                color:inherit;
            }
        }
    }

    .direct-contact{
        .svg-icon{
            margin-top: -.25rem;
            margin-right:1.25rem;
        }
    }

    // footer widgets

    .footer-section{
        padding-top:$section-spacer/2.5;
        padding-bottom:$section-spacer/2.5;
        &.footer-about{
            border-bottom:1px solid rgba(#fff, .1);
        }
        .footer-partners{
            display: flex;
            align-items:center;
            flex-wrap:wrap;
            img{
                margin-right: 2rem;
                margin-bottom: 1rem;
                max-width:100px;
            }
        }
        &.footer-widgets ul.menu{
            list-style: none;
            margin:0;
            padding:0;
            display: block;
            column-count: 2;
            column-gap: 30px;
        }
        .footer-logo{
            margin-bottom: 1rem;
            max-width:11rem;
            margin-top: .8rem

        }
        .location-info{
            padding-bottom: 0;
        }
        form#form_newslettersignup{
            fieldset{
                padding-bottom: 0;
            }
            .frm_form_field{
                margin-bottom: 0;
            }
            .frm_description p{
                margin:0;
                color:#fff!important;
                margin-bottom: .5rem;
                font-size:1.125rem;
            }
            .frm_fields_container{
                position: relative;
            }
            .frm_submit{
                position: absolute;
                top:0;
                right:0;
                margin:0;
                padding:5px;
                .button{
                    margin:0;
                    height:40px;
                }
            }
            input[type="text"],
            input[type="email"]{
                padding-right:90px!important;
            }
        }


    }
}

// news category 

.section.category-list{
    padding-top:3rem;
    padding-bottom: 3rem;
    margin-top: 0;
    border-bottom:1px solid #eee;
    .badge{
        display: inline-block;
        background:$primary;
        padding:0 .65rem;
        border-radius: $border-radius;
        margin-left: .5rem;
        color:#fff;
    }
    .content{
        display: flex;
        flex-direction:row;
        flex-wrap:wrap;
        .cat-item{
            margin-right: 1rem;
        }
    }
}

// header and nav

@media screen and (max-width: $bp-three) {
    .topbar > .container_12.v-align{
        display: flex;
        align-items:center;
        > .grid_3{
            flex-shrink:0;
            #header .logo{
                //max-width: 8rem;
            }
        }
    }
}


.topbar{
    .header-buttons{
        display: flex;
        justify-content:flex-end;
        align-items:center;
        @media screen and (max-width: $bp-one) {
            display: inline-flex;
        }
        .offcanvas-form-toggle{
            margin-left: 1rem;
        }
        .button{
            padding-left: 1rem!important;
            padding-right: 1rem!important;
            display: flex;
            justify-content:center;
            align-items:center;
            .svg-icon{
                position:relative;
                margin-right: 1rem;
            }
            span{
                display: block;
            }
            @media screen and (max-width: $bp-one) {
                display: inline-block;
                width: 3rem;
                text-align: center;
                .svg-icon{
                    margin:0;
                }
                span{
                    display: none;
                }
            }
        }
    }
}

// pagination

section.pagination{
    padding:$section-spacer/2 0;
    border-top:1px solid #eee;
    .page-numbers{
        @extend .button;
        padding:1rem 1.25rem;
    }
    h2{
        display: none;
    }
}

// smashballoon instagram

#sb_instagram .sbi_follow_btn a:focus,
#sb_instagram .sbi_follow_btn a:hover,
#sb_instagram #sbi_load a:focus,
#sb_instagram #sbi_load a:hover {
    box-shadow: none!important;
}

// small heading fix

p[style="text-align: center;"] .small-heading{
    display: block;
    margin-bottom: -1.5rem;
}

@media screen and (max-width: $bp-three) {
    .main-nav{
        transition:all .25s ease!important
    }
}

.social:not(.footer-social){
    margin-left:-.75rem;
    a{
        color:$dark;
    }
}


.page-contact-us{
    .contact-content{
        .contact-detail{
            margin-bottom: 2rem;
            strong{
                //display:block;
            }
            .contact-data{
                font-family:$font-primary;
                font-weight:$font-weight-regular;
            }
        }
        .social,
        .direct-contact{
            margin-top: 2rem;
            padding-top:2rem;
            border-top:1px solid #eee;
        } 
        @media screen and (max-width: $bp-three) {
            .direct-contact{
                border-top:0;
                padding-top:0;
            }
        }
    }
    .mobile__content{
        @media screen and (max-width: $bp-three) {     
            margin-bottom: 2rem;
        }
    }

}

body.single{
    .entry-meta{
        list-style-type: none;
        margin-left: 0;
        padding:0;
        display: flex;
        li:not(:last-child){
            margin-right:2rem;
        }
    }
    .section__news{
        @extend .bg-light, .section-padded;
    }
}

.bg-img{
    position: relative;
    .container_12{
        z-index: 2;
    }
}

html.has-animation{
    h1.brand-heading{
        .brand-heading__one,
        .brand-heading__two{
            opacity: 1;
            transform:none;
        }
    }
}

.bg-img .content-light{
    text-shadow:0px 2px 3px rgba(0,0,0,0.3);
}

.homepage-alert{
    font-size: .875rem;
    p{
        font-size:1em;
    }
    p:last-child,
    p:only-child{
        padding-bottom:0;
    }
    a{
        text-decoration: underline;
    }
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}
.cf:after {
    clear: both;
}
.cf {
    *zoom: 1;
}

#return-to-top{
    position:fixed;
    display: block;
    width:3rem;
    height:3rem;
    bottom:1rem;
    right:1rem;
    border-radius: 99px;
    background:darken($primary, 5%);
    color:$white;
    text-align: center;
    line-height: 3rem;
    z-index:10;
    box-shadow: 0px 0px 20px 0px rgba(#000, .1);
    opacity: 0;
    transform:translateY(1rem);
    visibility: hidden;
    pointer-events:none;
    transition:all .25s ease;
    .svg-icon{
        margin-top:-5px;
    }
    &.show{
        opacity:1;
        transform:none;
        visibility: visible;
        pointer-events:auto;
    }
    @media screen and (max-width: $bp-three) {
        display: none!important;
    }
}

// icon

.kc-brand-icon{
    margin-bottom: 1.5rem;
    &.icon_direction-down{
        transform:scaleY(-1);
    }
    &.icon_bg-primary path{
        fill:$primary;
    }
    &.icon_bg-secondary path{
        fill:$secondary;
    }
    &.icon_bg-light path{
        fill:$light;
    }
    &.icon_bg-white path{
        fill:$white;
    }
}
p > .kc-brand-icon{
    margin-bottom: 0;
}


// formidable tweaks

.frm_form_fields{
    .frm_submit{
        text-align: right;
    }
    button{
        box-shadow: none!important;
    }
}
.bg-primary,
.bg-secondary,
.bg-dark,
.bg-img{

}




.img-icon{
    img{
        height:3rem;
        width:auto;
        display: inline-block;
    }
}

.hm-img{
    &:not(.bg-cover){
        position: relative;
    }
    a.fancybox{
        height:100%;
        width:100%;
        display: block;
    }
    .hm-img-caption{
       position: absolute;
       top:0;
       left:0;
       width:100%;
       height:100%;
       z-index: 5;
       display: flex;
       padding:10px;
       align-items:flex-end;
       pointer-events:none;
       color:#fff;
        background: linear-gradient(to bottom, transparent, rgba(0,0,0,.5)) no-repeat bottom;
        background-size: 100% 50%;
    }
}

// hero

section.section__page-hero{
    position:relative;
    margin:0;
    h1{
       // padding: 0;
       // color:#fff;
        @media screen and (min-width: $bp-one+1) {
            font-size:4rem;
            line-height: .875;
        }
    }
    .container_12{
        min-height:30rem;
        display: flex;
        align-items:center;
        @media screen and (max-width: $bp-three) {
            display: block;
            min-height:initial!important;
        }    
    }

    .hero-img{
        position: absolute;
        top:0;
        right:0;
        width:55%;
        height:100%;
        img{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit:cover;
            object-position:center;
        }
        .mask{
            display: block;
            position: absolute;
            left:0;
            width:400px;
            height:100%;
            z-index:10;
            &.flip-mask{
                transform:scaleY(-1);
            }
            @media screen and (max-width: $bp-three) {
                display: none;
            }
        }
        @media screen and (max-width: $bp-three) {
            width:100%;
        }
    }

    .container_12{
        position: relative;
        z-index: 20;
    }

    @media screen and (max-width: $bp-three) {
        &:before{
            content: '';
            position:absolute;
            z-index: 2;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background:black;
            opacity: .65;
        }
    }

    &.bg-primary .hero-img .mask path{
        fill:$primary;
    }
    &.bg-secondary .hero-img .mask path{
        fill:$secondary;
    }
    &.bg-dark .hero-img .mask path{
        fill:$dark;
    }
    &.bg-light .hero-img .mask path{
        fill:$light;
    }
    &.bg-light-alt .hero-img .mask path{
        fill:$light-alt;
    }
    @media screen and (max-width: $bp-three) {
        color:#fff;
    }
}

// fancybox

div#fancybox-outer{
    box-shadow: 0px 0px 25px 0px rgba(#000, .25);
}

// full stops

h1,
h2,
h3{
    .brand-fullstop{
        color:$primary;
    }
}
.bg-primary{
    h1,
    h2,
    h3{
        .brand-fullstop{
            color:$secondary;
        }
    }
}

// fancy uls

#main .content,
#main .panel{
    ul:not(.tab-nav):not(.slick-dots):not(.social):not(.entry-meta):not(.list-unstyled){
        list-style: none;
        padding-left: 0;
        margin-left:0;
        li{
            padding-left:2rem;
            position:relative;
            &:before{
                content:'';
                position:absolute;
                top:.5rem;
                left:0;
                width:1.125rem;
                height:1.125rem;
                background:url('../images/check-alt.svg') no-repeat center center;
                background-size: cover;
            }
        }
    }
}

// page specific

@media (min-width:1500px){
    body.home .section__page-hero{
        .container_12{
            overflow: visible;
        }
        h1{
            position: relative;
        }
        h1 > span:not(.brand-fullstop){
            display: block;
            &:nth-child(1){
                font-size:5rem;
            }
            &:nth-child(2){
                margin-left:8.5rem;
            }
            &:nth-child(3){
                margin-top:-.5rem;
                margin-left: 3rem;
                font-size:5rem;
            }
        }
    }
}

body.page-contact-us section.section__page-hero .container_12{
    min-height:20rem;
}

body.page-about section.section__page-hero h1{
    //white-space: nowrap;
}

// misc fixes

.slick-gallery{
    .content,
    .panel{
        p:last-child{
            padding-bottom: 0;
        }
    }
}


.footer-section.credit-bar{
    padding:.5rem 0;
    background:darken($dark, 2%);
}

.content-light{
    .frm_forms .frm_primary_label,
    .frm_description{
        color:#fff!important;
    }   
}




.offcanvas-form{
    position: fixed;
    top: 0;
    left:0;
    height:100%;
    width:540px;
    z-index:999999;
    display: flex;
    align-items:center;
    padding:2rem;
    opacity:0;
    transform:translateX(-100%);
    visibility: hidden;
    transition:all .25s ease!important;
    .content{
        width:100%;
    }
    .offcanvas-close{
        position: absolute;
        top:2rem;
        right:2rem;
        background:#fff;
        text-align: center;
        width:3rem;
        height:3rem;
        line-height: 3rem;
        border-radius: 50%;
    }
}
body.show_offcanvas-form{
    overflow: hidden;
    .offcanvas-form{
        opacity:1;
        transform:none;
        visibility: visible;
    }
}

.bg-primary .frm_button_submit.button{
    background:$dark!important;
    border-color:$dark!important;
    &:hover{
        background:darken($dark, 5%)!important;
        border-color:darken($dark, 5%)!important;
    }
}

.section__side-by-side-content .slick-dots{
    position:absolute;
    bottom:1rem;
    width:100%;
    left:0;
}
.bg-primary .slick-dots li.slick-active button{
    background:$secondary!important;
}
.news-card .entry-meta-item{
    font-size:.875rem;
    font-weight: bold;
    display: block;
    margin-bottom: 1rem;
    .svg-icon{
        margin-right: .5rem;
    }
}









.section__features{
    .text-on-hover-card{
        .icon-img img{
            margin-bottom: 2rem;
            height:4rem;
        }
        .hover-content{
            transition:all .25s ease;
            opacity: 0;
            pointer-events:none;
            visibility: hidden;
            color:#fff;
            transform:translateY(1rem);
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            flex-direction:column;
            text-align: center;
            display: flex;
            justify-content:center;
            align-items:center;
            padding:1rem;
            background:$dark;
            p:last-child,
            p:only-child{
                padding-bottom:0;
            }
        }
        &:hover{
            .hover-content{
                opacity: 1;
                pointer-events:auto;
                transform:none;
                visibility: visible;
            }
        }
        @media(min-width:1680px){
            h3{
                display:block;
                padding-left:2rem;
                padding-right:2rem;
            }
        }
    }
}

.bg-dark .section__features .text-on-hover-card .hover-content{
    background:$primary;
}