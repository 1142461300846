.main-nav{
	margin:1rem 0;
	ul{
		display: inline-block;
		vertical-align: middle;
		position:relative;
		list-style: none;
		margin:0 1rem;
		padding:0;
		text-align: center;
		li{
			display:inline-block;
			position:relative;
			vertical-align: middle;
			a{
				transition:all $transition-duration $transition-ease;
				cursor: pointer;
				border-radius:0.25rem;
				//@include ui-hover;
				display: block;
				position: relative;
				//padding:1rem 0.25rem;
				//margin:0 1.25rem;
				padding:1rem;
				margin:0 .1rem;
				text-decoration: none;
				//color:$grey-dark;
				font-size:1rem;
				//font-size: .9rem;
				font-weight: $font-weight-regular;
				//font-weight: bold;
				text-transform: capitalize;
				&:hover{
					color:$color;
					background:$light;
				}
				@media screen and (max-width: $bp-one) {
					margin:0 0.5rem;
				}
				@media screen and (max-width: $bp-three) {
					font-size:0.75rem;
					padding:0.25rem 0.5rem;
					margin:0;
				}
			}
			&.current_page_item > a{
				color:$color;
				background:$light;
			}
			// submenu
			ul{
				margin:0;
				z-index: 9999;
				position:absolute;
				top:100%;
				left:0;
				width: 16rem;
				max-width: 16rem;
				padding:.25rem;
				text-align: left;
				background:$white;
				border-radius: $border-radius;
				box-shadow:0 0 0 1px rgba(0, 0, 0, 0.02), $box-shadow-lrg;
				transition: all .2s ease-in-out;
				opacity: 0;
				visibility: hidden;
				transform:translateY(.5rem) scale(.975);
				transform-origin:center top;

				li{
					display: block;
				}
				ul{
					top:0;
					left:100%;
				}
			}
			&.menu-item-has-children:hover{
				> a{
					color:$color;
					background:$light;
				}
				> ul{
					opacity: 1;
					visibility: visible;
					transform:none;
				}
			}
		}
	}
	.button{
		display: inline-block;
		vertical-align: middle;
		margin:0;
	}
}

.close_button,
.close_area,
.nav-hamburger{
	display: none;
}
@media screen and (max-width: $bp-two) {
	.nav-hamburger{
		display: inline-block;
		vertical-align: middle;
		margin:0;
		@include ui-hover;
		cursor: pointer;
		padding:1.9rem 1rem;
		width:4rem;
		text-align: center;
		//background-color: $color-base;
		span{
			$width:3px;
			transition:transform $transition-duration $transition-ease;
			display: block;
			margin:0 auto;
			width:8em;
			height:$width;
			position:relative;
			color:$color;
			font-size:0.25rem;
			text-indent:-999rem;
			padding: 0;
			background: currentcolor;
			&:before,&:after{
				border-radius:inherit;
				transition:inherit;
				content: '';
				position: absolute;
				top:0;
				left:0;
				right:0;
				height: $width;
				background: currentcolor;
			}
			&:before{ 	transform:translate(0, -2em); 		}
			&:after{ 	transform:translate(0, 2em); 		}
		}
		&:active span{
			transform:scale(0.9);
		}
	}
	body.overlay-main-nav{
		overflow: hidden;
		.nav-hamburger{
			 span{
				color:$color !important;
				transform:none;
				background-color:transparent;
				&:before{ 	transform:rotate(45deg) translate(0%, 0%); 	}
				&:after{ 	transform:rotate(-45deg) translate(0%, 0%); }
			}
			&:active span{
				&:before{ 	transform:rotate(55deg) translate(0%, 0%); 	}
				&:after{ 	transform:rotate(-55deg) translate(0%, 0%); }
			}
		}
		.main-nav{
			pointer-events:auto;
			transition-duration:250ms;
			transform:none;
			.close_area{ display: block; }
		}
	}
	.main-nav{
		.close_area,
		.close_button{ display: none; }
	}
	.nav-hamburger{
		z-index: 11;
		position:relative;
		display: inline-block;
	}
	.main-nav{
		z-index:99999;
		position: fixed;
		top:0;
		right:6rem;
		left:0;
		height:100vh;
		pointer-events:none;
		margin:0 auto;
		transform:translateX(-100%);
		transform-origin: 100% 0;
		transition:transform 400ms $ease-out-quad;
		box-shadow:$shadow;
		overflow: hidden;
		padding:3rem 1rem 1rem;
		background:rgba(white,0.8);
		background:$secondary;
		ul{
			display: block;
			li{
				display: block;
				a{
					margin-bottom: .5rem;
					display: block;
					//padding:0 0 1rem;
					color:$color;
					color:$white;
					font-size:1.25rem;
					font-weight: $font-weight-regular;
					text-align: left;
					border:0;
					&:hover{
						color:$white!important;
						background:rgba(#000, .15)!important;
					}
				}
				&.current-menu-item a{
					//color:$color;
					background:rgba(#000, .15);
					color:$white;
				}

				ul{
					margin:0;
					z-index: 9999;
					position:static;
					width:auto;
					max-width: none;
					padding:0;
					padding-left:1rem;
					text-align: left;
					background:0;
					border-radius: 0;
					box-shadow:none;
					transition: all .2s ease-in-out;
					opacity: 1;
					visibility: visible;
					transform:none;

					li{
						display: block;
					}
					ul{
						position: static;
					}
				}
				&.menu-item-has-children:hover{
					> a{
						color:$white;
						background:none;
					}
					> ul{
						opacity: 1;
						visibility: visible;
						transform:none;
					}
				}
			}

		}
		.button{
			display: block;
			margin:1rem auto 0 !important;
			width:100%;
		}
		.contact{
			display: block;
			margin:0 auto;
			font-size:1rem;
		}
		.close_area{
			transition:opacity 200ms ease-out;
			display: none;
			position:fixed;
			top:0;
			right:0;
			height: 100vh;
			left:0;
			opacity: 0;
			cursor: pointer;
			background:white;
		}
		.close_button{
			display: none;
			@include ui-hover;
			cursor: pointer;
			z-index: 2;
			position:absolute;
			top:0.5rem;
			right:0.5rem;
			padding:0.5rem;
			font-size: 2rem;
			line-height: 0;
			color:$white;
			border-radius:3px;
			&:focus{ box-shadow: 0 0 0 2px rgba(black,0.03); }
		}
	}
}