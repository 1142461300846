// Variables
$grid-margin: 1.2%;

// Calculations
$grid_amount: 12;

$column_1: 	( 100% / ($grid_amount / 1) );
$column_2: 	( 100% / ($grid_amount / 2) );
$column_3: 	( 100% / ($grid_amount / 3) );
$column_4: 	( 100% / ($grid_amount / 4) );
$column_5: 	( 100% / ($grid_amount / 5) );
$column_6: 	( 100% / ($grid_amount / 6) );
$column_7: 	( 100% / ($grid_amount / 7) );
$column_8: 	( 100% / ($grid_amount / 8) );
$column_9: 	( 100% / ($grid_amount / 9) );
$column_10: ( 100% / ($grid_amount / 10));
$column_11: ( 100% / ($grid_amount / 11));
$column_12: ( 100% / ($grid_amount / 12));

/* Containers
----------------------------------------------------------------------------------------------------*/
.container_12 {
	width: 100%;
	max-width:$max-width;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	&.overflow-visible{ overflow:visible; }
	&.full-width{ max-width: none; }

	&.v-align{ // pls dont break
	    display: flex;
	    align-items: center;
	}

	&.wide{ max-width: 1440px; }

	&.widest{ max-width: 2200px; }
}

/* Grid >> Global
----------------------------------------------------------------------------------------------------*/
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
	display: inline;
	position: relative;
	float: left;
	margin-left: $grid-margin;
	margin-right: $grid-margin;
}
/* Grid >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.grid_1 {	width: $column_1 - $grid-margin*2;	}
	.grid_2 {	width: $column_2 - $grid-margin*2;	}
	.grid_3 {	width: $column_3 - $grid-margin*2;	}
	.grid_4 {	width: $column_4 - $grid-margin*2;	}
	.grid_5 {	width: $column_5 - $grid-margin*2;	}
	.grid_6 {	width: $column_6 - $grid-margin*2;	}
	.grid_7 {	width: $column_7 - $grid-margin*2;	}
	.grid_8 {	width: $column_8 - $grid-margin*2;	}
	.grid_9 {	width: $column_9 - $grid-margin*2;	}
	.grid_10 {	width: $column_10 - $grid-margin*2;	}
	.grid_11 {	width: $column_11 - $grid-margin*2;	}
	.grid_12 {	width: $column_12 - $grid-margin*2;	}
} 

/* Prefix Extra Space >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.prefix_1 {	padding-left: $column_1;	}
	.prefix_2 {	padding-left: $column_2;	}
	.prefix_3 {	padding-left: $column_3;	}
	.prefix_4 {	padding-left: $column_4;	}
	.prefix_5 {	padding-left: $column_5;	}
	.prefix_6 {	padding-left: $column_6;	}
	.prefix_7 {	padding-left: $column_7;	}
	.prefix_8 {	padding-left: $column_8;	}
	.prefix_9 {	padding-left: $column_9;	}
	.prefix_10 {padding-left: $column_10;	}
	.prefix_11 {padding-left: $column_11;	}
}

/* Suffix Extra Space >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.suffix_1 {	padding-right: $column_1;	}
	.suffix_2 {	padding-right: $column_2;	}
	.suffix_3 {	padding-right: $column_3;	}
	.suffix_4 {	padding-right: $column_4;	}
	.suffix_5 {	padding-right: $column_5;	}
	.suffix_6 {	padding-right: $column_6;	}
	.suffix_7 {	padding-right: $column_7;	}
	.suffix_8 {	padding-right: $column_8;	}
	.suffix_9 {	padding-right: $column_9;	}
	.suffix_10 {padding-right: $column_10;	}
	.suffix_11 {padding-right: $column_11;	}
}

/* Push Space >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.push_1 {	left: $column_1;	}
	.push_2 {	left: $column_2;	}
	.push_3 {	left: $column_3;	}
	.push_4 {	left: $column_4;	}
	.push_5 {	left: $column_5;	}
	.push_6 {	left: $column_6;	}
	.push_7 {	left: $column_7;	}
	.push_8 {	left: $column_8;	}
	.push_9 {	left: $column_9;	}
	.push_10 {	left: $column_10;	}
	.push_11 {	left: $column_11;	}
}

/* Pull Space >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.pull_1 {	left: -$column_1;	}
	.pull_2 {	left: -$column_2;	}
	.pull_3 {	left: -$column_3;	}
	.pull_4 {	left: -$column_4;	}
	.pull_5 {	left: -$column_5;	}
	.pull_6 {	left: -$column_6;	}
	.pull_7 {	left: -$column_7;	}
	.pull_8 {	left: -$column_8;	}
	.pull_9 {	left: -$column_9;	}
	.pull_10 {	left: -$column_10;	}
	.pull_11 {	left: -$column_11;	}
}

/* Grid >> Children (Alpha ~ First, Omega ~ Last)
----------------------------------------------------------------------------------------------------*/
.alpha {	margin-left: 0 !important;	}
.omega {	margin-right: 0 !important;}

/* Alpha or Omega >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.grid_1.alpha, .grid_1.omega {	width: $column_1 - $grid-margin;	}
	.grid_2.alpha, .grid_2.omega {	width: $column_2 - $grid-margin;	}
	.grid_3.alpha, .grid_3.omega {	width: $column_3 - $grid-margin;	}
	.grid_4.alpha, .grid_4.omega {	width: $column_4 - $grid-margin;	}
	.grid_5.alpha, .grid_5.omega {	width: $column_5 - $grid-margin;	}
	.grid_6.alpha, .grid_6.omega {	width: $column_6 - $grid-margin;	}
	.grid_7.alpha, .grid_7.omega {	width: $column_7 - $grid-margin;	}
	.grid_8.alpha, .grid_8.omega {	width: $column_8 - $grid-margin;	}
	.grid_9.alpha, .grid_9.omega {	width: $column_9 - $grid-margin;	}
	.grid_10.alpha, .grid_10.omega {width: $column_10 - $grid-margin;	}
	.grid_11.alpha, .grid_11.omega {width: $column_11 - $grid-margin;	}
	.grid_12.alpha, .grid_12.omega {width: $column_12 - $grid-margin;	}
}

/* Alpha & Omega >> 12 Columns
----------------------------------------------------------------------------------------------------*/
.container_12 {
	.grid_1.alpha.omega {	width: $column_1;	}
	.grid_2.alpha.omega {	width: $column_2;	}
	.grid_3.alpha.omega {	width: $column_3;	}
	.grid_4.alpha.omega {	width: $column_4;	}
	.grid_5.alpha.omega {	width: $column_5;	}
	.grid_6.alpha.omega {	width: $column_6;	}
	.grid_7.alpha.omega {	width: $column_7;	}
	.grid_8.alpha.omega {	width: $column_8;	}
	.grid_9.alpha.omega {	width: $column_9;	}
	.grid_10.alpha.omega {	width: $column_10;	}
	.grid_11.alpha.omega {	width: $column_11;	}
	.grid_12.alpha.omega {	width: $column_12;	}
}



/* Breakpoints >> 12 Columns
----------------------------------------------------------------------------------------------------*/

@media screen and (max-width: $bp-two) {

	.container_12{
		.tablet_grid_1,
		.tablet_grid_2,
		.tablet_grid_3,
		.tablet_grid_4,
		.tablet_grid_5,
		.tablet_grid_6,
		.tablet_grid_7,
		.tablet_grid_8,
		.tablet_grid_9,
		.tablet_grid_10,
		.tablet_grid_11,
		.tablet_grid_12 {
			display: inline;
			float: left;
			margin-left: $grid-margin;
			margin-right: $grid-margin;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.container_12{
		.tablet_grid_1 {	width: $column_1 - $grid-margin*2;	}
		.tablet_grid_2 {	width: $column_2 - $grid-margin*2;	}
		.tablet_grid_3 {	width: $column_3 - $grid-margin*2;	}
		.tablet_grid_4 {	width: $column_4 - $grid-margin*2;	}
		.tablet_grid_5 {	width: $column_5 - $grid-margin*2;	}
		.tablet_grid_6 {	width: $column_6 - $grid-margin*2;	}
		.tablet_grid_7 {	width: $column_7 - $grid-margin*2;	}
		.tablet_grid_8 {	width: $column_8 - $grid-margin*2;	}
		.tablet_grid_9 {	width: $column_9 - $grid-margin*2;	}
		.tablet_grid_10 {	width: $column_10 - $grid-margin*2;	}
		.tablet_grid_11 {	width: $column_11 - $grid-margin*2;	}
		.tablet_grid_12 {	width: $column_12 - $grid-margin*2;	}
	}
	.container_12 {
		.tablet_grid_1.alpha, .tablet_grid_1.omega {	width: $column_1 - $grid-margin;	}
		.tablet_grid_2.alpha, .tablet_grid_2.omega {	width: $column_2 - $grid-margin;	}
		.tablet_grid_3.alpha, .tablet_grid_3.omega {	width: $column_3 - $grid-margin;	}
		.tablet_grid_4.alpha, .tablet_grid_4.omega {	width: $column_4 - $grid-margin;	}
		.tablet_grid_5.alpha, .tablet_grid_5.omega {	width: $column_5 - $grid-margin;	}
		.tablet_grid_6.alpha, .tablet_grid_6.omega {	width: $column_6 - $grid-margin;	}
		.tablet_grid_7.alpha, .tablet_grid_7.omega {	width: $column_7 - $grid-margin;	}
		.tablet_grid_8.alpha, .tablet_grid_8.omega {	width: $column_8 - $grid-margin;	}
		.tablet_grid_9.alpha, .tablet_grid_9.omega {	width: $column_9 - $grid-margin;	}
		.tablet_grid_10.alpha, .tablet_grid_10.omega {	width: $column_10 - $grid-margin;	}
		.tablet_grid_11.alpha, .tablet_grid_11.omega {	width: $column_11 - $grid-margin;	}
		.tablet_grid_12.alpha, .tablet_grid_12.omega {	width: $column_12 - $grid-margin;	}
	}
	.container_12 {
		.tablet_grid_1.alpha.omega {	width: $column_1;	}
		.tablet_grid_2.alpha.omega {	width: $column_2;	}
		.tablet_grid_3.alpha.omega {	width: $column_3;	}
		.tablet_grid_4.alpha.omega {	width: $column_4;	}
		.tablet_grid_5.alpha.omega {	width: $column_5;	}
		.tablet_grid_6.alpha.omega {	width: $column_6;	}
		.tablet_grid_7.alpha.omega {	width: $column_7;	}
		.tablet_grid_8.alpha.omega {	width: $column_8;	}
		.tablet_grid_9.alpha.omega {	width: $column_9;	}
		.tablet_grid_10.alpha.omega {	width: $column_10;	}
		.tablet_grid_11.alpha.omega {	width: $column_11;	}
		.tablet_grid_12.alpha.omega {	width: $column_12;	}
	}
}


@media screen and (max-width: $bp-three) {

	.container_12{
		&.v-align{ display:block; }
		.grid_1,
		.grid_2,
		.grid_3,
		.grid_4,
		.grid_5,
		.grid_6,
		.grid_7,
		.grid_8,
		.grid_9,
		.grid_10,
		.grid_11,
		.grid_12 {
			display: block;
			float: none;
			width:100%;
			left:0;
			padding-left: 0;
			padding-right: 0;
			margin-left: auto;
			margin-right: auto;
			&.alpha,&.omega,&.alpha.omega{ width: 100%; }
		}
	}

	
	.container_12{
		.mobile_grid_1,
		.mobile_grid_2,
		.mobile_grid_3,
		.mobile_grid_4,
		.mobile_grid_5,
		.mobile_grid_6,
		.mobile_grid_7,
		.mobile_grid_8,
		.mobile_grid_9,
		.mobile_grid_10,
		.mobile_grid_11,
		.mobile_grid_12 {
			display: inline;
			float: left;
			margin-left: $grid-margin;
			margin-right: $grid-margin;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.container_12{
		.mobile_grid_1 {	width: $column_1 - $grid-margin*2;	}
		.mobile_grid_2 {	width: $column_2 - $grid-margin*2;	}
		.mobile_grid_3 {	width: $column_3 - $grid-margin*2;	}
		.mobile_grid_4 {	width: $column_4 - $grid-margin*2;	}
		.mobile_grid_5 {	width: $column_5 - $grid-margin*2;	}
		.mobile_grid_6 {	width: $column_6 - $grid-margin*2;	}
		.mobile_grid_7 {	width: $column_7 - $grid-margin*2;	}
		.mobile_grid_8 {	width: $column_8 - $grid-margin*2;	}
		.mobile_grid_9 {	width: $column_9 - $grid-margin*2;	}
		.mobile_grid_10 {	width: $column_10 - $grid-margin*2;	}
		.mobile_grid_11 {	width: $column_11 - $grid-margin*2;	}
		.mobile_grid_12 {	width: $column_12 - $grid-margin*2;	}
	}
	.container_12 {
		.mobile_grid_1.alpha, .mobile_grid_1.omega {	width: $column_1 - $grid-margin;	}
		.mobile_grid_2.alpha, .mobile_grid_2.omega {	width: $column_2 - $grid-margin;	}
		.mobile_grid_3.alpha, .mobile_grid_3.omega {	width: $column_3 - $grid-margin;	}
		.mobile_grid_4.alpha, .mobile_grid_4.omega {	width: $column_4 - $grid-margin;	}
		.mobile_grid_5.alpha, .mobile_grid_5.omega {	width: $column_5 - $grid-margin;	}
		.mobile_grid_6.alpha, .mobile_grid_6.omega {	width: $column_6 - $grid-margin;	}
		.mobile_grid_7.alpha, .mobile_grid_7.omega {	width: $column_7 - $grid-margin;	}
		.mobile_grid_8.alpha, .mobile_grid_8.omega {	width: $column_8 - $grid-margin;	}
		.mobile_grid_9.alpha, .mobile_grid_9.omega {	width: $column_9 - $grid-margin;	}
		.mobile_grid_10.alpha, .mobile_grid_10.omega {	width: $column_10 - $grid-margin;	}
		.mobile_grid_11.alpha, .mobile_grid_11.omega {	width: $column_11 - $grid-margin;	}
		.mobile_grid_12.alpha, .mobile_grid_12.omega {	width: $column_12 - $grid-margin;	}
	}
	.container_12 {
		.mobile_grid_1.alpha.omega {	width: $column_1;	}
		.mobile_grid_2.alpha.omega {	width: $column_2;	}
		.mobile_grid_3.alpha.omega {	width: $column_3;	}
		.mobile_grid_4.alpha.omega {	width: $column_4;	}
		.mobile_grid_5.alpha.omega {	width: $column_5;	}
		.mobile_grid_6.alpha.omega {	width: $column_6;	}
		.mobile_grid_7.alpha.omega {	width: $column_7;	}
		.mobile_grid_8.alpha.omega {	width: $column_8;	}
		.mobile_grid_9.alpha.omega {	width: $column_9;	}
		.mobile_grid_10.alpha.omega {	width: $column_10;	}
		.mobile_grid_11.alpha.omega {	width: $column_11;	}
		.mobile_grid_12.alpha.omega {	width: $column_12;	}
	}
}

@media screen and (max-width: $bp-four) {
/*
	.container_12{
		.tablet_grid_1,
		.tablet_grid_2,
		.tablet_grid_3,
		.tablet_grid_4,
		.tablet_grid_5,
		.tablet_grid_6,
		.tablet_grid_7,
		.tablet_grid_8,
		.tablet_grid_9,
		.tablet_grid_10,
		.tablet_grid_11,
		.tablet_grid_12 {
			display: block;
			float: none;
			width:100%;
			padding-left: 0;
			padding-right: 0;
			margin-left: auto;
			margin-right: auto;
			&.alpha,&.omega{ width: 100%; }
		}
	}
*/
}

@media (min-width:1080px){

	/* Grid >> 12 Columns no Gutter
	----------------------------------------------------------------------------------------------------*/
	.container_12.no-gutter {
		.grid_1 {	width: $column_1; margin:0;	}
		.grid_2 {	width: $column_2; margin:0;	}
		.grid_3 {	width: $column_3; margin:0;	}
		.grid_4 {	width: $column_4; margin:0;	}
		.grid_5 {	width: $column_5; margin:0;	}
		.grid_6 {	width: $column_6; margin:0;	}
		.grid_7 {	width: $column_7; margin:0;	}
		.grid_8 {	width: $column_8; margin:0;	}
		.grid_9 {	width: $column_9; margin:0;	}
		.grid_10 {	width: $column_10; margin:0;	}
		.grid_11 {	width: $column_11; margin:0;	}
		.grid_12 {	width: $column_12; margin:0;	}
	}

}