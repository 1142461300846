$amount : 30;
$i: 0;
@for $i from 1 through $amount{
	.delay-#{$i*100}ms{ animation-delay:#{$i*100}ms !important; }
}

$in-distance: 2rem;

@keyframes fadeIn{
	0%{ opacity:0; }
	100%{ opacity:1; }
}
@keyframes fadeOut{
	0%{ opacity:1; }
	100%{ opacity:0; }
}

@keyframes fadeInDown{
	0%{ opacity:0; transform:translate(0,-$in-distance); }
	100%{ opacity:1; transform:none; }
}

@keyframes fadeInUp{
	0%{ opacity:0; transform:translate(0,$in-distance); }
	100%{ opacity:1; transform:none; }
}


@keyframes fadeInLeft{
	0%{ opacity:0; transform:translate(-$in-distance,0); }
	100%{ opacity:1; transform:none; }
}
@keyframes fadeInRight{
	0%{ opacity:0; transform:translate($in-distance,0); }
	100%{ opacity:1; transform:none; }
}


@keyframes funAnimateIn{
	0%{ opacity:0; transform-origin:100% 50%;transform:rotate(-15deg) translate(-$in-distance,0); }
	100%{ opacity:1; transform:none; }
}


.animate-light-spin{ animation:lightSpin 10000ms ease-in-out both infinite; }
@keyframes lightSpin{
	0%{ transform:rotate(0deg); }
	20%{ transform:rotate(10deg); }
	40%{ transform:rotate(-10deg); }
	60%{ transform:rotate(20deg); }
	100%{ transform:rotate(0deg); }
}