/* Mixins */
@mixin Transitions( $arguments: all $transition-duration $transition-ease ) {
	-moz-transition:		$arguments;
	-webkit-transition:		$arguments;
	-o-transition:			$arguments;
	transition:				$arguments;
}

@mixin BorderRadius( $arguments: $border-radius ) {
	-webkit-border-radius: 	$arguments;
	-moz-border-radius: 	$arguments;
	border-radius: 			$arguments;
}

@mixin TextShadow( $arguments: $text-shadow ){
	text-shadow:       $arguments;
}
@mixin BoxShadow( $arguments: $box-shadow ){
	-moz-box-shadow:       $arguments;
	-webkit-box-shadow:    $arguments;
	box-shadow:            $arguments;
}

@mixin Columns( $amount ){
	column-count:$amount;
	-moz-column-count:$amount; /* Firefox */
	-webkit-column-count:$amount; /* Safari and Chrome */
}
@mixin linear-gradient($from, $to) {
	background: $from;
	background: linear-gradient(to right, $from, $to)
}
@mixin linear-gradient-vertical($from, $to) {
	background: $from;
	background: linear-gradient($from, $to)
}
@mixin ui-hover(){
	transition:transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.1);
	&:hover{	transition-duration:0.2s; }
	&:active{	transition-duration:0.1s; }
}

@mixin delay-children($rule, $number, $duration) {
	@for $i from 1 through ($number + 1){
		&:nth-child(#{$i}){
			#{$rule}-delay: (#{$i*$duration});
		}
	}
}


@mixin Animated(
	$name:none,
	$duration:$animation-duration,
	$delay: 0s,
	$iteration-count:1,
	$timing-function:$animation-ease,
	$fill-mode:both
){

	-webkit-animation-name: $name;
	   -moz-animation-name: $name;
		 -o-animation-name: $name;
			animation-name: $name;

	-webkit-animation-duration: $duration;
	   -moz-animation-duration: $duration;
		-ms-animation-duration: $duration;
		 -o-animation-duration: $duration;
			animation-duration: $duration;

	@include AnimationDelay($delay);

	-webkit-animation-iteration-count: $iteration-count;
	   -moz-animation-iteration-count: $iteration-count;
		-ms-animation-iteration-count: $iteration-count;
		 -o-animation-iteration-count: $iteration-count;
			animation-iteration-count: $iteration-count;

	-webkit-animation-timing-function: $timing-function;
	   -moz-animation-timing-function: $timing-function;
		-ms-animation-timing-function: $timing-function;
		 -o-animation-timing-function: $timing-function;
			animation-timing-function: $timing-function;

	-webkit-animation-fill-mode: $fill-mode;
	   -moz-animation-fill-mode: $fill-mode;
		-ms-animation-fill-mode: $fill-mode;
		 -o-animation-fill-mode: $fill-mode;
			animation-fill-mode: $fill-mode;
}

@mixin AnimationDelay($delay){
	-webkit-animation-delay: $delay;
	   -moz-animation-delay: $delay;
		-ms-animation-delay: $delay;
		 -o-animation-delay: $delay;
			animation-delay: $delay;
}