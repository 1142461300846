.frm_button_submit{ @extend .button; }

.frm_screen_reader,
.frm_verify{
	position: absolute;
	top:0;
	left:0;
	width:0;
	height:0;
	overflow: hidden;
}
.frm_submit{
	text-align: center;
}
.form-field{
	margin-left:0;
	margin-right: auto;
	width:98.5%;
	width:100%;
	margin-right:0;
}
.frm_half{
	display: inline-block;
	vertical-align: top;
	width:49%;
}
@media screen and (max-width: $bp-three) {
	//.form-field,
	.frm_half{
		display:block;
		margin-left:1rem;
		margin-right: 1rem;
		width:auto;
	}
}
.frm_description{ font-size:0.8rem; color:$grey; }
.frm_message{
	@include Animated(fadeInDown);
	border-radius:3px;
	display: block;
	text-align:center;
	margin: 0 auto 1rem;
	padding: 0 0.25rem;
	color:$color;
	font-size:1.2rem;
	font-weight:$font-weight-bold;
	background:$white;
	border:2px solid rgba(black,0.04);
	p{ padding:0; text-shadow:none; }
}
.frm_error_style{
	@include Animated(fadeInDown);
	border-radius:3px;
	display: block;
	text-align:center;
	margin: 0 auto 4rem;
	padding: 0.25rem 0.5rem;
	font-size:1rem;
	font-weight:$font-weight-bold;
	border:2px solid rgba(black,0.04);
	color:$white;
	background-color:$error;
	p{ padding:0; text-shadow:none; }
}
.frm_error{
	@include Animated(fadeInDown);
	border-radius:3px;
	display: inline-block;
	width:auto;
	text-align:center;
	margin: 0 auto 1rem;
	padding: 0 0.5rem;
	font-size:0.75rem;
	font-weight:$font-weight-bold;
	border:2px solid rgba(black,0.04);
	color:$white;
	background-color:$error;
	p{ padding:0; text-shadow:none; }
}