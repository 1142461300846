/*

.bg-white{		background-color: $white;		}
.bg-red{		background-color: $red;			}
.bg-orange{		background-color: $orange;		}
.bg-green{		background-color: $green;		}
.bg-blue{		background-color: $blue;		}
.bg-blue-light{	background-color: $blue-light;	}
.bg-purple{		background-color: $purple;		}

.bg-white-fade{		background-color: $white-fade;		}
.bg-red-fade{		background-color: $red-fade;		}
.bg-orange-fade{	background-color: $orange-fade;		}
.bg-green-fade{		background-color: $green-fade;		}
.bg-blue-fade{		background-color: $blue-fade;		}
.bg-blue-light-fade{background-color: $blue-light-fade;	}
.bg-purple-fade{	background-color: $purple-fade;		}

.color-white{		color: $white;		}
.color-red{			color: $red;		}
.color-orange{		color: $orange;		}
.color-green{		color: $green;		}
.color-blue{		color: $blue;		}
.color-blue-light{	color: $blue-light;	}
.color-purple{		color: $purple;		}

*/

.page-header{
	img{
		display: block;
		width:20rem;
		margin:0 auto 2rem;
	}
}

.js-count-up{
	display: block;
	line-height: 1.4;
	font-size:4rem;
	font-weight: $font-weight-black;
}
