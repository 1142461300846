.slick-list{
	z-index: 3;
	margin:0 5%;
	//padding:0 5%;
	position: relative;
	overflow: hidden;

	/*

	&:before,
	&:after{
		z-index: 2;
		content:'';
		pointer-events: none;
		position: absolute;
		top:0;
		bottom:0;
		width:5rem;
		$color:$background;
		background:linear-gradient( to left, 
			rgba($color, 1) 0%,
			rgba($color, 0.738) 19%,
			rgba($color, 0.541) 34%,
			rgba($color, 0.382) 47%,
			rgba($color, 0.278) 56.5%,
			rgba($color, 0.194) 65%,
			rgba($color, 0.126) 73%,
			rgba($color, 0.075) 80.2%,
			rgba($color, 0.042) 86.1%,
			rgba($color, 0.021) 91%,
			rgba($color, 0.008) 95.2%,
			rgba($color, 0.002) 98.2%,
			rgba($color, 0) 100%
		);
	}
	&:before{
		left:0;
		transform:scaleX(-1);
	}
	&:after{
		right:0;
	}
	*/
	@media screen and (max-width: $bp-two) {
		//padding: 0 10%;
	}
	@media screen and (max-width: $bp-three) {
		//padding: 0 10% 0 0;
		//margin:0 10%;
	}
	.slick-slide{
		transition:opacity 200ms ease-out;
		//opacity: 0.2;
		&.slick-current{
			//opacity: 1;
		}
	}
}
/*
.bg-light .slick-list{
	&:before,
	&:after{
		$color:#F2F5FB;
		background:linear-gradient( to left, 
			rgba($color, 1) 0%,
			rgba($color, 0.738) 19%,
			rgba($color, 0.541) 34%,
			rgba($color, 0.382) 47%,
			rgba($color, 0.278) 56.5%,
			rgba($color, 0.194) 65%,
			rgba($color, 0.126) 73%,
			rgba($color, 0.075) 80.2%,
			rgba($color, 0.042) 86.1%,
			rgba($color, 0.021) 91%,
			rgba($color, 0.008) 95.2%,
			rgba($color, 0.002) 98.2%,
			rgba($color, 0) 100%
		);
	}
}
*/
.slick-dots-titles{
	padding-top:4rem;
	@media screen and (max-width: $bp-three) {
		padding-top:0;
		.bg-cover,
		.slick-dots{
			display: none !important;
		}
	}
	.slick-dots{
		z-index: 1;
		position:absolute;
		top:0;
		left:0;
		right:0;
		text-align: center;
		li{
			display: inline-block;
			position:relative;
			padding:0;
			margin-right:1rem;
			&:after{
				position:absolute;
				left:100%;
				top:50%;
				content: '';
				width:1rem;
				height: 1px;
				background:rgba(white,0.2);
			}
			&:last-child:after{ display: none; }
			button{
				margin:0 auto;
				width:auto;
				height: auto;
				padding:0.5rem 2rem;
				font-size:1rem;
				color:$white;
				transform:none;
				background:transparent;
				border:1px solid white;
				text-transform: uppercase;
			}
			//&.slick-active button{ background-color: $blue; border-color:transparent; }
			&.slick-active button{ background-color: $color-base; border-color:transparent; }
		}
	}
}