/* Media Queries */
/*  */
@media screen and (min-width: $bp-two) {
	.tablet_show{ display:none !important; }
}
@media screen and (min-width: $bp-three) { // desktop
	.mobile_show{ display:none !important; }
}
@media screen and (max-width: $bp-three) { // mobile
	.tablet_show{ display:none !important; }
	.mobile_show{ display:block !important; }
	.mobile_hide{ display:none !important; }
}



/*  */
@media screen and (max-width: $bp-one) {
}

/*  */
@media screen and (max-width: $bp-two) {
	h1.h0{
		font-size:3.5rem !important;
	}
}
@media screen and (max-width: $bp-three) {

	h1.display{
		font-size:2.5rem !important;
	}

/*
	h1{ font-size: 1.8rem; padding-bottom: 1rem; }
	h2{ font-size: 1.5rem; padding-bottom: 1rem; }
	h3{ font-size: 1.4rem; padding-bottom: 1rem; }
	h4{ font-size: 1.3rem; padding-bottom: 1rem; }
	h5{ font-size: 1.1rem; padding-bottom: 1rem; }
	h6{ font-size: 1.0rem; padding-bottom: 1rem; }
*/

	h1{ font-size: 3rem; padding-bottom: 1rem; }
	h2{ font-size: 2.5rem; padding-bottom: 1rem; }
	h3{ font-size: 1.4rem; padding-bottom: 1rem; }
	h4{ font-size: 1.3rem; padding-bottom: 1rem; }
	h5{ font-size: 1.1rem; padding-bottom: 1rem; }
	h6{ font-size: 1.0rem; padding-bottom: 1rem; }
	p{ font-size: 1rem; }
	p.large{ font-size: 1.1rem; }

	.button{
		line-height:1.5;
		font-size:0.8rem;
		margin:1rem auto 0;
		padding-left:1rem;
		padding-right:1rem;
	}
	footer.footer{
		//padding:3rem 0;
		.footer-title{
			display: none;
		}
	}
	section{
		&.bg-light+.bg-light{
			//padding-top:0 !important;
		}
		&.section-large{
			padding:2rem 0;
		}
		&:first-of-type{
			img{
				display: block;
				//width:70%;
				width:100%;
				margin:0 auto;
			}
		}
	}
	.bg-rounded-top{
		margin-top: 2rem;
		&:before{
			height:10rem;
			top:-3rem;
		}
	}
	main#main{
		padding-top:6rem;
		padding-top: 0;
	}
	.content{
		padding:1rem 1.5rem;
		img{
			display: block;
			//width:70%;
			width:100%;
			margin:0 auto;
		}
	}
	.panel{
		width:auto;
	//	padding:0.5em 1.5rem 2rem;
		margin:1rem 1rem 1rem;
		box-shadow:$shadow-sml;
		.content{
			padding:2rem 0rem;
		}
		img{
			max-width:65%;
		}
		.cover-image{
			margin-left: -1.5rem;
			margin-right: -1.5rem;
		}
	}
	.panel .cover-image{
		display: flex;
		justify-content: center;
		align-items:center;
		padding:1rem;
		h3{
			padding:0;
			text-align: left;
			margin:0;
			width:100%;
		}
		img{
			max-width:20%;
			margin:0 1rem 0 0;
		}
	}
	.panel-offset{
		margin:0 auto;
	}
	footer.footer .callout{
		margin:-6rem auto 0;
	}
	.footer-text{
		text-align: center;
	}

	.topbar .nav-bar{ position:absolute; }

	.cutoff-angle-top:before{ display: none; }
	.bg-wave-bottom,.bg-wave-top{
		height:4rem;
		z-index: 1;
	}
}

/*  */
@media screen and (max-width: $bp-four) {
}