html.has-animation{
//	section .content,
//	section .panel,
//	.grid li,
	.js-animate{
		will-change: transform;
		transition:all 400ms ease-in-out;
		opacity: 0;
		transform:translate(0, 1rem);
	}
//	section .content,
//	section .panel,
//	.grid li,
	.js-animate{
		&.in-viewport{
			transition-duration:700ms;
			opacity: 1;
			transform:none;
		}
	}
	/*
	section.in-viewport{
		.panel{
			transition-duration:700ms;
			opacity: 1;
			transform:none;
		}
	}
	*/

	.h0{
		span{
			@include delay-children(animation, 3, 200ms);
			animation:fadeInUp $animation-duration $animation-ease both;
		}
	}

	.bg-contain{
		will-change: transform;
		transition:all 300ms $animation-ease 300ms;
		opacity: 0;
		transform:translate(0, 1rem);
		&.in-viewport{
			transition-duration:1800ms;
			transform:none;
			opacity: 1;
		}
	}

	.fadeInUp.animateIn{
		animation:fadeOut 600ms $ease-in-quad both;
		&.in-viewport{
			animation:fadeInUp 1200ms $ease-out-quad both;
		}
	}

}
.fadeIn{
	animation:fadeIn 1200ms $animation-ease both;
}
.fadeInUp{
	animation:fadeInUp 1200ms $animation-ease both;
}



@media (prefers-reduced-motion) {
	.container_12{ transform:none !important; }
	*{
		transition:none !important;
		animation:none !important;
	}
}