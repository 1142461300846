@charset "UTF-8";

// Default Variables
$slick-font-family: '' !default;

$slick-arrow-color: $color !default;
$slick-dot-color: $grey-light !default;
$slick-dot-color-active: $color-base !default;
$slick-dot-size: 0.75rem !default;


/* Slider */
.slick-list {
    z-index:1;
    padding:0 0;
    .slick-loading & {
        background: #fff url($img_path + "ajax-loader.gif") center center no-repeat;
    }
}

.slick-gallery.show-previews{
    .slick-list {
        overflow:visible;
        .slick-slide{
            opacity:0.2;
            &.slick-current,
            &.slick-active{
                opacity:1;
            }
        }
    }
}

/* Arrows */

.slick-prev,
.slick-next {
  //  @include BoxShadow;
    @include ui-hover;
    border-radius:$border-radius*0.25;
    border-radius: 50%;
    z-index:9;
    cursor: pointer;
    //display: none !important;
    position: absolute;
    top: 50%;
    //margin:0 -2rem;
    height: 3rem;
    width: 3rem;
    color:$white;
    //background:var(--blue);
    background:$primary;
    font-size: 0;
    transform:translateY(-50%);
    &:hover{
        outline: none;
        transform:scale(1.01) translateY(-50%);
    }
    &:active{
        transform:scale(0.99) translateY(-50%);
    }
    &.slick-disabled{
        opacity: 0.6;
        &:before {
        }
    }
    &:before,
    &:after{
        content: '';
        position:absolute;
        width:4px;
        height:1rem;
        background-color: currentColor;
    }
    &:before{
        top:0.75rem;
        transform:rotate(-45deg);
    }
    &:after{
        top:1.25rem;
        transform:rotate(45deg);
    }
}

.slick-prev {
    left: .5rem;
    &:before{
        left:1.3rem;
        transform:rotate(45deg);
    }
    &:after{
        left:1.3rem;
        transform:rotate(-45deg);
    }
}

.slick-next {
    right: .5rem;
}

/* Dots */

.slick-dotted.slick-slider {
    //margin-bottom: 30px;
}

.slick-dots {
    z-index: 9;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0 1rem;
    margin: 0;
    width: 100%;
    li {
        @include ui-hover;
        $size:$slick-dot-size;
        position: relative;
        display: inline-block;
        margin: 0;
        padding: $size * 0.5;
        cursor: pointer;
        &:first-child:last-child{ display: none; }
        button {
            cursor: pointer;
            display: block;
            width: $size;
            height: $size;
            @include ui-hover;
            border-radius:10rem;
            font-size:0;
            margin:0;
            padding:0;
            color:transparent;
            background:rgba($color,1);
            opacity: 0.5;
        }
        &:hover button {
            opacity: 1;
        }
        &.slick-active button{
            opacity: 1;
            transform:scale(1.5);
            border-color:$slick-dot-color-active;
            background:$slick-dot-color-active;
        }
    }
}
