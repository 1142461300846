/* Icons */
.svg-icon{
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	font-size:1.0em;
	line-height:0;
    text-rendering: auto;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	svg,
	img{
		display:block;
		fill:currentColor;
		max-width:100%;
		max-height:100%;
		width: 100%;
		height: 100%;
	}


	&.lg,&.lrg,&.large{
		font-size: 3em;
		line-height: 0.75em;
		vertical-align: -.0667em;
	}

	&.circle{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position:relative;
		margin:1rem 1rem 2rem;
		&:before{
			content: '';
			border-radius:10rem;
			position: absolute;
			top:-1rem;
			left:-1rem;
			right:-1rem;
			bottom:-1rem;
			background-color: currentColor;
			opacity: 0.1;
		}
	}
}

.icon-text-hoz{
	display: block;
	margin:0 auto;
	position: relative;
	padding: 1.5rem 1rem 1rem 6.5rem;
	max-width:42rem;
	.svg-icon{
		position:absolute;
		left:0;
		top:0;
	}
}

.fa-spin{ 	animation: fa-spin 2s infinite linear;		}
.fa-pulse{	animation: fa-spin 1s infinite steps(8);	}
@keyframes fa-spin {
	0% {	transform: rotate(0deg);	}
	100% {	transform: rotate(360deg);	}
}