.headroom {
	position: fixed !important;
	width:100%;
    will-change: transform;
    transition: all 750ms ease-in-out;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
	transition-duration:400ms;
    transform: translateY(-100%);
}